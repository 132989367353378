import { graphql } from 'gatsby'
import Collection from '../containers/Tags'

export default Collection

export const pageQuery = graphql`
  query allBlogsByTagsQuery($slug: String) {
    tagsBlog: allStrapiBlog(
      filter: { tags: { elemMatch: { name: { eq: $slug } } } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        tags {
          id
          strapi_id
          url
          name
        }
        id
        slug
        title
        date
        featuredImage {
          ImageSharp_vertical: localFileImageSharp {
            gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
        }
        excerpt {
          data {
            excerpt
          }
        }
        body {
          data {
            body
          }
        }
        authors {
          id
          slug
          name
          title
        }
        categories {
          id
          name
          slug
        }
        featured
      }
      pageInfo {
        pageCount
        hasNextPage
        currentPage
        hasPreviousPage
      }
      totalCount
    }

    tagVideo: allStrapiVideo(
      filter: { tags: { elemMatch: { name: { eq: $slug } } } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        tags {
          id
          strapi_id
          url
          name
        }
        id
        slug
        title
        date
        featuredImage {
          ImageSharp_vertical: localFileImageSharp {
            gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
        }
        excerpt {
          data {
            excerpt
          }
        }
        body {
          data {
            body
          }
        }
        categories {
          id
          name
          slug
        }
        authors {
          id
          name
          title
          slug
          updatedAt
          social {
            name
            url
          }
        }
      }
    }

    tagEvent: allStrapiEvent(
      filter: { tags: { elemMatch: { name: { eq: $slug } } } }
      sort: { fields: [eventdate], order: DESC }
    ) {
      nodes {
        tags {
          id
          strapi_id
          url
          name
        }
        id
        slug
        title
        eventdate
        featuredImage {
          ImageSharp_vertical: localFileImageSharp {
            gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
        }
        excerpt {
          data {
            excerpt
          }
        }
        body {
          data {
            body
          }
        }
        categories {
          id
          name
          slug
        }
      }
    }
  }
`
