import React from 'react'
import { Layout, Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import { useEventSlug, usePostExcerpt,getReadTimeFromBlog } from '@helpers-blog'
import { readingTime } from 'reading-time-estimator'
import Seo from '@widgets/Seo'
import { attachPostDetails } from '@utils-blog'

const Tags = ({ data: { tagsBlog, tagVideo, tagEvent }, ...props }) => {
  if (tagEvent.nodes && tagEvent.nodes.length > 0) {
    tagEvent.nodes.forEach(item => {
      item.slug = useEventSlug(item.title)
      item.excerpt = usePostExcerpt(item.title)
      item.bodyContent = usePostExcerpt(item.title)
    })
  }
  if (tagsBlog.nodes && tagsBlog.nodes.length > 0) {
    tagsBlog.nodes.forEach(item => {
      item.bodyContent = usePostExcerpt(item.title)
      item.excerpt = item.bodyContent
    })
  }
  if (tagVideo.nodes && tagVideo.nodes.length > 0) {
    tagVideo.nodes.forEach(item => {
      item.excerpt = usePostExcerpt(item.title)
      item.bodyContent = usePostExcerpt(item.title)
    })
  }
  tagsBlog.nodes.forEach(ele => {
    ele.bodyContent = getReadTimeFromBlog(ele.title)
    if (ele.bodyContent && ele.bodyContent.body) {
      const text = ele.bodyContent.body.data.body.replace(/(<([^>]+)>)/gi, '')
      ele.readingTime = readingTime(text, 220)
      ele.callFrom='Blog'
    }
     })

  tagsBlog.nodes = addKey(tagsBlog.nodes, 'Blog')
  tagVideo.nodes = addKey(tagVideo.nodes, 'Video')
  tagEvent.nodes = addKey(tagEvent.nodes, 'Event')

  const data = [...tagVideo.nodes, ...tagsBlog.nodes, ...tagEvent.nodes]

  const item = groupBy([...data], c => c.belongTo)
  function groupBy(xs, f) {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    )
  }
  function addKey(list, key) {
    list.forEach(item => {
      item.belongTo = key
    })
    return list
  }
  const object = { nodes: data }
  attachPostDetails(object) // Attach missing post details

  return (
    <Layout {...props}>
      <Seo title={props?.pageContext?.slug} description={'tags'} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle header={props?.pageContext?.slug} totalCount={data.length} />
      </Stack>
      <Divider />
      {Object.keys(item).map(key => {
        return (
          <Stack title={key}>
            <Main>
              {item[key] && (
                <>
                  <CardList
                    nodes={item[key]}
                    variant={['horizontal-md', 'vertical']}
                    columns={[1, 2, 3, 3]}
                    isTagBlog={true}
                    linkKey={key}
                    omitCategory
                  />
                  <Divider />
                </>
              )}
            </Main>
            <Divider />
          </Stack>
        )
      })}

      <Divider />
      {/* <PreFooter>
        <Pagination
          // {...posts.pageInfo}
          {...{
            slug: `/tags/${props?.pageContext?.slug}/`
          }}
        />
      </PreFooter> */}
    </Layout>
  )
}

export default Tags
